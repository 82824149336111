<template>
  <div class="history-point-page margin-content">
    <div class="container py-4">
      <div class="d-flex flex-column mb-3">
        <h3 class="font-weight-bold">ポイントボーナス履歴</h3>
        <div class="list-history my-2">
          <b-card no-body>
            <!-- <b-tabs card> -->
            <!-- <b-tab title="Available" active>
                <Tables
                  :items="dataAvailable"
                  :fields="fields"
                  :itemsPerPage="10"
                >
                  <template v-slot:created_at="{ item }">
                    <td>
                      {{
                        new Date(item.created_at).toLocaleString("ja-JP", {
                          timeZone: "Asia/Tokyo",
                        })
                      }}
                    </td>
                  </template>
                  <template v-slot:action_type="{ item }">
                    <td v-if="item.action_type == 1">
                      <b-badge variant="info">Login</b-badge>
                    </td>
                    <td v-if="item.action_type == 2">
                      <b-badge variant="info">Sign Up</b-badge>
                    </td>
                    <td v-if="item.action_type == 3">
                      <b-badge variant="info">After Click Content</b-badge>
                    </td>
                    <td v-if="item.action_type == 4">
                      <b-badge variant="info">After Purchase</b-badge>
                    </td>
                    <td v-if="item.action_type == 5">
                      <b-badge variant="info">Grant To User</b-badge>
                    </td>
                    <td v-if="item.action_type == 6">
                      <b-badge variant="info">Update All Info</b-badge>
                    </td>
                    <td v-if="item.action_type == 7">
                      <b-badge variant="info">Automatic Grant</b-badge>
                    </td>
                    <td v-if="item.action_type == 8">
                      <b-badge variant="info">After Giving Slag</b-badge>
                    </td>
                    <td v-if="item.action_type == 9">
                      <b-badge variant="info">Setting All Content</b-badge>
                    </td>
                    <td v-if="item.action_type == 10">
                      <b-badge variant="info">After Answer Question</b-badge>
                    </td>
                    <td v-if="item.action_type == null">
                      <b-badge variant="info"></b-badge>
                    </td>
                  </template>
                </Tables>
              </b-tab>
              <b-tab title="UnAvailable">
                <Tables
                  :items="dataUnAvailable"
                  :fields="fields"
                  :itemsPerPage="10"
                >
                  <template v-slot:created_at="{ item }">
                    <td>
                      {{
                        new Date(item.created_at).toLocaleString("ja-JP", {
                          timeZone: "Asia/Tokyo",
                        })
                      }}
                    </td>
                  </template>
                  <template v-slot:action_type="{ item }">
                    <td v-if="item.action_type == 1">
                      <b-badge variant="info">Login</b-badge>
                    </td>
                    <td v-if="item.action_type == 2">
                      <b-badge variant="info">Sign Up</b-badge>
                    </td>
                    <td v-if="item.action_type == 3">
                      <b-badge variant="info">After Click Content</b-badge>
                    </td>
                    <td v-if="item.action_type == 4">
                      <b-badge variant="info">After Purchase</b-badge>
                    </td>
                    <td v-if="item.action_type == 5">
                      <b-badge variant="info">Grant To User</b-badge>
                    </td>
                    <td v-if="item.action_type == 6">
                      <b-badge variant="info">Update All Info</b-badge>
                    </td>
                    <td v-if="item.action_type == 7">
                      <b-badge variant="info">Automatic Grant</b-badge>
                    </td>
                    <td v-if="item.action_type == 8">
                      <b-badge variant="info">After Giving Slag</b-badge>
                    </td>
                    <td v-if="item.action_type == 9">
                      <b-badge variant="info">Setting All Content</b-badge>
                    </td>
                    <td v-if="item.action_type == 10">
                      <b-badge variant="info">After Answer Question</b-badge>
                    </td>
                    <td v-if="item.action_type == null">
                      <b-badge variant="info"></b-badge>
                    </td>
                  </template>
                </Tables>
              </b-tab> -->

            <!-- <b-tab title="All"> -->
            <Tables
              :items="allHistoryPointData"
              :fields="fields"
              :itemsPerPageSelect="{}"
            >
              <template v-slot:created_at="{ item }">
                <td>
                  {{
                    new Date(item.created_at).toLocaleString("ja-JP", {
                      timeZone: "Asia/Tokyo",
                    })
                  }}
                </td>
              </template>
              <template v-slot:action_type="{ item }">
                <td v-if="item.action_type == 1">
                  <b-badge variant="info">Login</b-badge>
                </td>
                <td v-if="item.action_type == 2">
                  <b-badge variant="info">Sign Up</b-badge>
                </td>
                <td v-if="item.action_type == 3">
                  <b-badge variant="info">After Click Content</b-badge>
                </td>
                <td v-if="item.action_type == 4">
                  <b-badge variant="info">After Purchase</b-badge>
                </td>
                <td v-if="item.action_type == 5">
                  <b-badge variant="info">Grant To User</b-badge>
                </td>
                <td v-if="item.action_type == 6">
                  <b-badge variant="info">Update All Info</b-badge>
                </td>
                <td v-if="item.action_type == 7">
                  <b-badge variant="info">Automatic Grant</b-badge>
                </td>
                <td v-if="item.action_type == 8">
                  <b-badge variant="info">After Giving Slag</b-badge>
                </td>
                <td v-if="item.action_type == 9">
                  <b-badge variant="info">Setting All Content</b-badge>
                </td>
                <td v-if="item.action_type == 10">
                  <b-badge variant="info">After Answer Question</b-badge>
                </td>
                <td v-if="item.action_type == null">
                  <b-badge variant="info"></b-badge>
                </td>
              </template>
            </Tables>
            <!-- </b-tab> -->
            <!-- </b-tabs> -->
          </b-card>
          <div
            class="d-flex justify-content-center position-relative"
            v-if="allHistoryPointPageSettingsData.last_page"
          >
            <p class="font-weight-bold">
              {{ allHistoryPointPageSettingsData.total }}件中{{
                allHistoryPointDataLength
              }}件を表示
            </p>
          </div>
          <div
            class="d-flex justify-content-center position-relative"
            v-if="allHistoryPointPageSettingsData.last_page"
          >
            <CPagination
              v-if="allHistoryPointPageSettingsData.total"
              :activePage.sync="page"
              :pages="allHistoryPointPageSettingsData.last_page"
              align="center"
            />
            <!-- <div class="custom-selectLimitPage">
          <b-form-select
            v-model="limit"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPage"
            @change="changeLimitPage()"
          ></b-form-select>
        </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { Constants } from "../../utils/constants";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "HistoryPoint",
  data() {
    return {
      // dataUnAvailable: [],
      // dataAvailable: [],
      fields: tableFields.HISTORYPOINT,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      limit: 3,
      page: 1,
    };
  },
  components: {
    Tables,
  },
  created() {
    this.getAllHistoryPoints(1);
  },
  watch: {
    // allHistoryPoint() {
    //   const d = new Date();
    //   let time = d.getTime();
    //   this.dataAvailable = this.allHistoryPoint.data.data.filter(
    //     (item) => new Date(item.day_expiration).getTime() > time
    //   );
    //   this.dataUnAvailable = this.allHistoryPoint.data.data.filter(
    //     (item) => new Date(item.day_expiration).getTime() < time
    //   );
    // },
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        this.getListUsers(this.page);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    page() {
      this.getAllHistoryPoints(this.page);
    },
    allHistoryPoint() {
      this.page = this.allHistoryPointPageSettingsData.current_page;
    },
  },
  computed: {
    ...mapGetters(["allHistoryPoint"]),
    allHistoryPointPageSettingsData() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.allHistoryPoint.hasOwnProperty("data")) {
        return this.allHistoryPoint.data;
      }
      return {};
    },
    allHistoryPointData() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.allHistoryPoint.hasOwnProperty("data")) {
        return this.allHistoryPoint.data.data;
      }
      return [];
    },
    allHistoryPointDataLength() {
      return this.allHistoryPointData.length;
    },
  },
  methods: {
    ...mapActions({ getAllHistoryPointById_2: "getAllHistoryPointById_2" }),
    getAllHistoryPoints(page) {
      if (page === undefined) {
        page = this.page;
      }
      const formData = {
        page: page,
        data: {
          shop_id: this.shop_id,
          limit: this.limit,
        },
      };
      this.getAllHistoryPointById_2(formData);
    },
    changeLimitPage() {
      this.getAllHistoryPoints(1);
    },
  },
};
</script>
<style lang="scss">
.history-point-page {
  .list-history {
    a {
      color: #4a4a4a;
    }
  }
}
</style>
